import './style.css'

import { RequiredAuthProvider, RedirectToLogin } from 'propelauth-vike/client'
import { usePageContext } from 'vike-react/usePageContext'
import theme from './theme'
import useVikeClientRouter from './router'
import useSession from './session'
import { DashboardLayout } from '@toolpad/core/DashboardLayout'
import { PageContainer } from '@toolpad/core/PageContainer'
import { AppProvider } from '@toolpad/core/AppProvider'
import { useRedirectFunctions, useLogoutFunction } from 'propelauth-vike/client'
import type { Navigation, Branding } from '@toolpad/core'
import DashboardIcon from '@mui/icons-material/Dashboard'
import type React from 'react'
import logoUrl from '../assets/logo.svg'

const NAVIGATION: Navigation = [
    {
        kind: 'header',
        title: 'Main items',
    },
    {
        segment: '',
        title: 'Dashboard',
        icon: <DashboardIcon />,
    },
]

const BRANDING: Branding = {
    title: 'Nutshell AI',
    logo: <img src={logoUrl} alt='Nutshell logo' />,
}

const AppLayout = ({ children }: { children: React.ReactNode }) => {
    const session = useSession()
    const logoutFn = useLogoutFunction()
    const { redirectToLoginPage } = useRedirectFunctions()
    const router = useVikeClientRouter(import.meta.env.PUBLIC_ENV__APP_URL)
    return (
        <AppProvider
            navigation={NAVIGATION}
            branding={BRANDING}
            session={session}
            authentication={{
                signIn: redirectToLoginPage,
                signOut: logoutFn,
            }}
            router={router}
            theme={theme}
        >
            {children}
        </AppProvider>
    )
}

export default function LayoutDefault({
    children,
}: {
    children: React.ReactNode
}) {
    const { urlOriginal } = usePageContext()
    const pageContent = (
        <AppLayout>
            <DashboardLayout>
                <PageContainer>{children}</PageContainer>
            </DashboardLayout>
        </AppLayout>
    )

    return (
        // Wrap everything behind PropelAuth's RequiredAuthProvider
        <RequiredAuthProvider
            authUrl={import.meta.env.PUBLIC_ENV__AUTH_URL}
            displayWhileLoading={<div />}
            displayIfLoggedOut={<RedirectToLogin postLoginRedirectPath={urlOriginal} />}
        >
            {pageContent}
        </RequiredAuthProvider>
    )
}
