// 'use client'
// import { createTheme } from '@mui/material/styles'

// const theme = createTheme({
//     palette: {
//         mode: 'light',
//     },
//     typography: {
//         fontFamily: 'Roboto',
//     },
//     // components: {
//     //     MuiAlert: {
//     //         styleOverrides: {
//     //             root: ({ ownerState }) => ({
//     //                 ...(ownerState.severity === 'info' && {
//     //                     backgroundColor: '#60a5fa',
//     //                 }),
//     //             }),
//     //         },
//     //     },
//     // },
// })

// export default theme

'use client'
import { createTheme } from '@mui/material/styles'

const theme = createTheme({
    cssVariables: {
        colorSchemeSelector: 'data-toolpad-color-scheme',
    },
    colorSchemes: { light: true, dark: true },
    components: {
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true,
            },
        },
    },
})

export default theme
