import type { Router } from '@toolpad/core'
import { navigate as vikeNavigate } from 'vike/client/router'

const useVikeClientRouter = (initialUrl: string) => {
    const url = new URL(initialUrl)
    const router = {
        pathname: url.pathname,
        searchParams: url.searchParams,
        navigate: (newUrl: string | URL) => vikeNavigate(newUrl.toString()),
    }
    return router
}

export default useVikeClientRouter
