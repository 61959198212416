import type { Session } from '@toolpad/core'

import { usePageContext } from 'vike-react/usePageContext'

const useSession = () => {
    const { user } = usePageContext()
    const session = <Session>{
        user: {
            ...user,
            id: user.userId,
            name: [user.firstName, user.lastName].join(' '),
            image: user.properties.picture_url,
            email: user.email,
        },
    }
    return session
}

export default useSession
